import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import YogAdmin from '.';
import { useAppDispatch, useAppSelector } from '../../../helpers/store';
import { SelectField } from '../../../v2/components/SelectorField';
import { RespState, fetchYogEvents } from './yogAdmin.duck';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

export const YogAdminContainer = () => {
  const { classes } = useStyles();
  const { events, eventsApiState } = useAppSelector((state) => state.admin.yogAdmin);
  const dispatch = useAppDispatch();
  const [activeEvent, setActiveEvent] = useState<string>('');

  // Fetch the registerable events.
  useEffect(() => {
    dispatch(fetchYogEvents());
  }, []);

  useEffect(() => {
    if (events.length > 0) {
      setActiveEvent(events[0]._id);
    }
  }, [events]);

  if ([RespState.IDLE, RespState.PENDING].includes(eventsApiState)) {
    return (
      <div className={classes.container}>
        <LoadingSpinner />
      </div>
    );
  }

  if (eventsApiState === RespState.FAILED) {
    return (
      <div className={classes.container}>
        An error occured while loading upcoming events. Please try again later.
      </div>
    );
  }

  if (!events.length) {
    return <div className={classes.container}>No upcoming events found.</div>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.eventDropdownContainer}>
        <SelectField
          placeholder={'Event'}
          options={events.map((event) => ({ label: event.title, value: event._id }))}
          onChange={(_: string, value: string) => setActiveEvent(value)}
          maxSelect={1}
          inputValue={activeEvent}
        />
      </div>
      {activeEvent ? (
        <YogAdmin eventId={activeEvent} />
      ) : (
        'Please select an event to see registrations.'
      )}
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: 'white',
    padding: '0 16px'
  },
  eventDropdownContainer: {
    maxWidth: '300px'
  }
}));
