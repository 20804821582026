import { FC, useEffect } from 'react';
import { destroy, isValid, isDirty, formValueSelector } from 'redux-form';
import { DialogTitle } from '@material-ui/core';
import BasicInfoSection from './sections/BasicInfoSection';
import PersonalInfoSection from './sections/PersonalInfoSection';
import PanelFooter from '../PanelFooter';
import * as FORMS from '../../constants/forms';
import ContactCardMenu from '../menus/ContactCardMenu';
import { closeModal } from '../../actions/common.actions';
import { contactActions } from '../../views/Contacts/contacts.ducks';
import icon_close from '../../assets/icons/icon_cross.svg';
import { CopyIcon } from '../CopyIcon';
import { FOLLOW_UP } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { useMembers } from '../../views/Contacts/useMembers';
import { getSearchFilterValues } from '../../views/Contacts/components/utils';
import { IFilterFormValues } from '../filter-panel/FilterForm.types';

interface ContactPanelProps {
  myUserAccount?: boolean;
}

const ContactPanel: FC<ContactPanelProps> = ({ myUserAccount }) => {
  const state = useAppSelector((state) => state);
  const { clickedUser: initialValues, formSubmitted } = useAppSelector(
    (state) => state.contactForm
  );
  const { searchValue, filteredCity, filteredState } = useAppSelector(
    (state) => state.contactsData
  );
  const form = useAppSelector((state) => state.form);
  const filterFormValues: IFilterFormValues = form?.[FORMS.FILTER_FORM]?.values ?? {};

  const dispatch = useAppDispatch();

  const isFormValid = isValid(myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state);
  const isFormDirty = isDirty(myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state);

  const selectedValues = formValueSelector(
    myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO
  )(state, 'birthDate', 'addressDetails', 'profileImageUrl');

  const { getMembers } = useMembers();

  useEffect(() => {
    return () => {
      dispatch(destroy(myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO));
    };
  }, []);

  useEffect(() => {
    if (!myUserAccount) {
      if (formSubmitted) {
        // If contact was updated, then fetch for the individual contact's data
        if (initialValues && initialValues.id) {
          // Otherwise, a new contact was created, so fetch the entire list
          if (initialValues.contactStatus === 'yog_pending') {
            const filterValues = getSearchFilterValues(
              filterFormValues,
              filteredCity,
              filteredState
            );
            getMembers({ filter: filterValues, pageNumber: 1, search: searchValue });
          } else {
            dispatch(contactActions.fetchContactById(initialValues.id));
          }
        } else {
          // Otherwise, a new contact was created, so fetch the entire list
          // when using from a followup screen, we don't need to fetch the list.
          // For follow up user might not even have access to contacts, which will cause api to fail.
          if (!window.location.href.includes(FOLLOW_UP.value)) {
            getMembers({ pageNumber: 1, search: searchValue });
          }
        }
      }
    }
  }, [formSubmitted]);

  const { updatedAt, yogId } = initialValues;
  const isUnverified =
    initialValues.contactStatus === 'yog_pending' || initialValues.contactStatus === 'unverified';
  let uploadingImage = false;
  const currentProfileImageUrl = selectedValues.profileImageUrl;
  if (currentProfileImageUrl instanceof Object) {
    uploadingImage = true;
  }

  return (
    <div className="contact-form">
      <div className="contact-form-top-elements">
        <div className="contact-form-top">
          <div className="padding-left padding-sm-right">
            <div className="contact-form-top-row">
              <DialogTitle style={{ padding: 0 }}>
                <div className="contact-form-title margin-md-bottom margin-md-top">
                  <div className="contact-header-name">
                    {myUserAccount ? (
                      <h4 className="title-text padding-xsm-right">My User Account</h4>
                    ) : (
                      <h4 className="title-text padding-xsm-right">Profile</h4>
                    )}
                    {!myUserAccount && yogId ? (
                      <div className="contact-card-yog-badge">Yog</div>
                    ) : null}
                    {initialValues.id ? <CopyIcon content={initialValues.id} /> : null}
                  </div>
                  <div>
                    {!myUserAccount && updatedAt ? (
                      <div className="contact-form-updated">(Updated: {updatedAt})</div>
                    ) : null}
                  </div>
                </div>
              </DialogTitle>
              <div className="contact-form-top-row-buttons">
                {!myUserAccount ? (
                  <ContactCardMenu id="contactUsMenu" contact={initialValues} />
                ) : null}
                <img
                  className="contact-form-dismiss"
                  onClick={() => {
                    dispatch(closeModal);
                  }}
                  src={icon_close}
                />
              </div>
            </div>
            <BasicInfoSection myUserAccount={myUserAccount} initialValues={initialValues} />
          </div>
        </div>
        <div className="padding">
          <PersonalInfoSection initialValues={initialValues} myUserAccount={myUserAccount} />
        </div>
      </div>
      <PanelFooter
        form={myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO}
        uploadingImage={uploadingImage}
        isFormDirty={isFormDirty}
        isFormValid={isFormValid}
        isUnverified={isUnverified}
        getMembers={getMembers}
      />
    </div>
  );
};

export default ContactPanel;
