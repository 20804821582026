import { IFilterFormValues } from '../../../components/filter-panel/FilterForm.types';

// Converts the filter form values to the GQL search filter values.
export const getSearchFilterValues = (
  filterFormValues?: IFilterFormValues,
  city?: string,
  state?: string
) => {
  return {
    adminAttributes: filterFormValues?.adminAttributes?.map((type) => type.value),
    attributes: filterFormValues?.attributes?.map((attr) => attr.value),
    audiences: filterFormValues?.audiences?.map((type) => type.value),
    contactStatus: filterFormValues?.unverified ? 'yog_pending' : undefined,
    emailType: filterFormValues?.emailType,
    city: city ? city?.toLowerCase() : undefined,
    state: state ? state?.toLowerCase() : undefined,
    followUp: filterFormValues?.followUp?.unassigned ? 'unassigned' : undefined,
    gender: filterFormValues?.gender,
    mandal: filterFormValues?.mandal ?? undefined,
    maritalStatus: filterFormValues?.maritalStatus,
    muktType: filterFormValues?.muktType?.map((type) => type.value),
    noReference: filterFormValues?.noReference || undefined
  };
};
