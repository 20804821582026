import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { SelectField } from '../../../../v2/components/SelectorField';
import { IFilters } from '../yogAdmin.duck';
import { STATES } from '../../../../assets/data/us_states';
import FilterButtonGroup from '../components/FilterButtonGroup/FilterButtonGroup';
import clearIcon from '../../../../assets/icons/icon_close_small_black.svg';
import { REGISTRATION_STATUS } from '../constants/RegistrationStatus';
import { COUNTRIES_PLUS_NONUS } from '../../../../util/countries';
import { useRegistrationOptions } from '../useRegistrationOptions';

interface YogAdminFiltersProps {
  activeFilters: IFilters;
  handleClearAllFilters: () => void;
  isError: boolean;
  isLoading: boolean;
  isSuperAdmin?: boolean;
  mandalOptions?: { name: string; value: string }[];
  maxPageNumbers: null | number;
  onFilterSubmit: (values: IFilters) => void;
  onCancel?: () => void;
  cancelLabel?: string;
  eventId: string;
}

const YogAdminFilters = ({
  activeFilters,
  handleClearAllFilters,
  isError,
  isSuperAdmin,
  onFilterSubmit,
  onCancel,
  mandalOptions,
  cancelLabel = 'Close',
  eventId
}: YogAdminFiltersProps) => {
  const { classes } = useStyles();

  const [formValues, setFormValues] = useState<IFilters>(activeFilters);
  const registrationOptions = useRegistrationOptions(eventId);

  const statusFilter = useMemo(() => {
    const statusList = [
      { text: 'Pending', value: REGISTRATION_STATUS.PENDING },
      { text: 'Approved', value: REGISTRATION_STATUS.APPROVED },
      { text: 'Rejected', value: REGISTRATION_STATUS.REJECTED },
      { text: 'Hold', value: REGISTRATION_STATUS.HOLD }
    ];
    if (isSuperAdmin && registrationOptions?.accommodation) {
      statusList.push({ text: 'Paid Override', value: REGISTRATION_STATUS.PAID_OVERRIDE });
    }
    return statusList;
  }, [isSuperAdmin, registrationOptions]);

  const accommodationFilter = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' }
  ];

  useEffect(() => {
    setFormValues(activeFilters);
  }, [activeFilters]);

  const handleUpdateFormValue = (
    field: keyof IFilters,
    value: string | string[] | boolean | undefined
  ) => {
    setFormValues({
      ...formValues,
      [field]: value
    });
  };

  // Show `Clear All` button if filters are active
  const activeFilterCount = Object.keys(activeFilters).filter(
    (k) => activeFilters[k as keyof IFilters]
  ).length;
  const hasActiveFilter = activeFilterCount > 0;

  // Disable submit button if form is not dirty
  const isFormDirty = useMemo(() => {
    return JSON.stringify(activeFilters) != JSON.stringify(formValues);
  }, [activeFilters, formValues]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateFormValue('name', e.target.value);
  };

  const handleSelectMandal = (_: string, mandalName: string) => {
    handleUpdateFormValue('mandal', mandalName);
  };

  const handleSelectState = (_: string, stateName: string) => {
    handleUpdateFormValue('state', stateName);
  };

  const handleSelectCountry = (_: string, countryName: string) => {
    handleUpdateFormValue('country', countryName);
  };

  const handleSelectStudent = (isSelected?: string) => {
    handleUpdateFormValue('isStudent', isSelected);
  };

  const handleSelectGender = (selectedGender?: string) => {
    handleUpdateFormValue('gender', selectedGender);
  };

  const handleSelectStatus = (selectedStatus?: string) => {
    handleUpdateFormValue('status', selectedStatus);
  };

  const handleSelectAccommodation = (selectedAccommodation?: string) => {
    handleUpdateFormValue('accommodationNeeded', selectedAccommodation);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isFormDirty) {
      handleSubmitFilters();
    }
  };

  const handleSubmitFilters = useCallback(() => {
    onFilterSubmit(formValues);
    if (onCancel) {
      onCancel();
    }
  }, [formValues]);

  return (
    <div className={classes.container}>
      <div>
        <header className={classes.header}>
          {isError ? <Alert severity={'error'}>Failed to Load contacts, try again</Alert> : null}
          <h3 className={classes.headerText}>
            Filters{' '}
            {hasActiveFilter ? (
              <button onClick={handleClearAllFilters} className={classes.clearButton}>
                Clear All {`(${activeFilterCount})`}
              </button>
            ) : null}
          </h3>
        </header>
        <section className={classes.filters}>
          <div className={classes.searchFilter}>
            <input
              className={classes.search}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={formValues.name}
              placeholder={'Search name'}
            />
            {formValues.name ? (
              <button
                onClick={() => handleUpdateFormValue('name', '')}
                className={classes.clearSearchButton}
              >
                <img src={clearIcon} />
              </button>
            ) : null}
          </div>
          {mandalOptions ? (
            <div className={classes.filterSection}>
              <h5 className={classes.filterHeader}>Mandal</h5>
              <SelectField
                className={classes.state}
                containerClassName={classes.stateContainer}
                placeholder={'Mandal'}
                options={mandalOptions}
                onChange={handleSelectMandal}
                label=""
                maxSelect={1}
                inputValue={formValues.mandal}
              />
              {formValues.mandal ? (
                <button className={classes.clearButton} onClick={() => handleSelectMandal('', '')}>
                  Clear
                </button>
              ) : null}
            </div>
          ) : null}
          {isSuperAdmin ? (
            <div className={classes.filterSection}>
              <h5 className={classes.filterHeader}>Gender</h5>
              <FilterButtonGroup
                buttons={[
                  { text: 'Bhaiyo', value: 'male' },
                  { text: 'Behno', value: 'female' }
                ]}
                onClickHandler={handleSelectGender}
                value={formValues.gender}
              />
            </div>
          ) : null}
          <div className={classes.filterSection}>
            <h5 className={classes.filterHeader}>Status</h5>
            <FilterButtonGroup
              buttons={statusFilter}
              onClickHandler={handleSelectStatus}
              value={formValues.status}
            />
          </div>
          {registrationOptions?.accommodation ? (
            <div className={classes.filterSection}>
              <h5 className={classes.filterHeader}>Accommodation</h5>
              <FilterButtonGroup
                buttons={accommodationFilter}
                onClickHandler={handleSelectAccommodation}
                value={formValues.accommodationNeeded}
              />
            </div>
          ) : null}
          <div className={classes.filterSection}>
            {registrationOptions?.accommodation ? (
              <>
                <h5 className={classes.filterHeader}>Other</h5>
                <FilterButtonGroup
                  buttons={[{ text: 'Student', value: 'isStudent' }]}
                  onClickHandler={handleSelectStudent}
                  value={formValues.isStudent ? 'isStudent' : ''}
                />
              </>
            ) : null}
          </div>
          <div className={classes.filterSection}>
            <h5 className={classes.filterHeader}>Location</h5>
            <SelectField
              className={classes.state}
              containerClassName={classes.stateContainer}
              placeholder={'State'}
              options={STATES}
              onChange={handleSelectState}
              label=""
              maxSelect={1}
              inputValue={formValues.state}
            />
            {formValues.state ? (
              <button className={classes.clearButton} onClick={() => handleSelectState('', '')}>
                Clear
              </button>
            ) : null}
          </div>
          {isSuperAdmin && (
            <div className={classes.filterSection}>
              <h5 className={classes.filterHeader}>Country</h5>
              <SelectField
                className={classes.state}
                containerClassName={classes.stateContainer}
                placeholder={'Country'}
                options={COUNTRIES_PLUS_NONUS}
                onChange={handleSelectCountry}
                label=""
                maxSelect={1}
                inputValue={formValues.country}
              />
              {formValues.country ? (
                <button className={classes.clearButton} onClick={() => handleSelectCountry('', '')}>
                  Clear
                </button>
              ) : null}
            </div>
          )}
        </section>
        <section className={classes.submitSection}>
          <button
            className={classes.applyFilterButton}
            disabled={!isFormDirty}
            onClick={handleSubmitFilters}
          >
            Apply Filters
          </button>
          {onCancel ? (
            <button className={classes.clearButton} onClick={onCancel}>
              {cancelLabel}
            </button>
          ) : null}
        </section>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  applyFilterButton: {
    backgroundColor: '#ff5a52',
    color: 'white',
    borderRadius: '8px',
    padding: '16px 48px',
    fontWeight: 'bold',
    border: '2px solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    cursor: 'pointer',

    '&:focus,&:hover': {
      border: '2px solid #3A4463'
    },

    '&:disabled': {
      opacity: '.5',
      cursor: 'not-allowed',
      border: '2px solid transparent'
    }
  },
  clearButton: {
    background: 'transparent',
    color: '#067bc2',
    border: 'none',
    fontWeight: 'bold',
    width: 'fit-content',
    cursor: 'pointer',

    '&:focus': {
      border: '1px solid #067bc2'
    }
  },
  clearSearchButton: {
    position: 'absolute',
    right: 0,
    top: '14px',
    background: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer',

    '> img': {
      height: '12px'
    },
    '&:focus': {
      border: '1px solid #848EAC'
    }
  },
  container: {
    padding: theme.spacing(2),
    background: '#f9fafb',
    position: 'relative',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      height: 'unset',
      border: '1px solid #E8EBF4',
      borderRadius: '4px'
    }
  },
  contentListContainer: {
    marginTop: '1rem',
    height: 'calc(100% - 206px)',
    width: '100%',

    '&::-webkit-scrollbar': {
      borderRadius: '9px',
      width: '4px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '9px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '9px',
      backgroundColor: 'darkgrey'
    }
  },
  contentListItem: {
    width: '100%'
  },
  header: {
    marginBottom: '2.5rem'
  },
  searchIcon: {
    flex: 1
  },
  headerText: {
    flex: 6,
    fontSize: '1rem',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  loading: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  loadingInner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  searchFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  search: {
    border: '1px solid #848EAC',
    borderRadius: '4px',
    color: '#848EAC',
    height: '43px',
    padding: '8px 24px 8px 16px',
    fontSize: '18px'
  },
  state: {
    border: '1px solid #848EAC',
    borderRadius: '4px',
    height: '43px',
    minWidth: '200px',
    maxWidth: '350px',
    textTransform: 'capitalize',
    '&:placeholder': {
      color: '#3A4463'
    }
  },
  stateContainer: {
    marginBottom: '4px'
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20
  },
  filterHeader: {
    color: '#3A4463',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    margin: '0 0 8px 0'
  },
  filterSection: {
    width: '100%'
  },
  submitSection: {
    marginTop: '36px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center'
  }
}));

export default YogAdminFilters;
